import React from 'react';
import { Link } from 'gatsby';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardMedia,
  Hidden,
  Typography,
} from '@material-ui/core';

import { Article } from '../../types';
import { colorPalette, screenWidthSettings } from '../../constants';
import AnimatedButtonInternal from '../AnimatedButtonInternal';
import FadeInSection from '../FadeInSection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '95vw',
      maxWidth: screenWidthSettings.largeLaptopScreenSize,
      margin: '15px 0 15px 0',
      display: 'flex',
      alignItems: 'flex-start',
      border: '3px solid white',
      borderTop: '5px solid rgba(230,167,86,0.4)',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '300px',
        flexDirection: 'column',
      },
      boxShadow: '0 1px 5px 0px rgba(38,59,94,0.3)',
      '&:hover,&:focus-within': {
        borderTop: '5px solid #FFA52C',
        boxShadow: '0 2px 15px 5px rgba(38,59,94,0.3)',
        transition: '0.25s ease-out',
      },
    },
    cardMedia: {
      borderRadius: theme.shape.borderRadius,
      height: '200px',
      width: '200px',
      alignSelf: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 'auto',
      },
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    articleTitle: {
      fontWeight: 'bold',
      color: 'inherit',
    },
    description: {
      fontWeight: 'lighter',
    },
    buttonContainer: {
      marginTop: '1rem',
    },
  })
);

const ResearchCard = ({ article }: { article: Article }): JSX.Element => {
  const classes = useStyles();
  // const thumbnailImage = require('../../assets/research' + article.thumbnail);
  return (
    <FadeInSection>
      <Card className={classes.root}>
        <CardMedia
          className={classes.cardMedia}
          component='img'
          alt={article.title + ' thumbnail'}
          image={article.thumbnail}
        />
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.articleTitle}
            variant='h6'
            component={Link}
            to={`/research/` + article.id}
          >
            {article.title}
          </Typography>
          <Hidden xsDown>
            <Typography
              className={classes.description}
              variant='body2'
              component='p'
            >
              {article.description}
            </Typography>
          </Hidden>
          <div className={classes.buttonContainer}>
            <AnimatedButtonInternal
              buttonText='Read Article'
              buttonTextColor='black'
              buttonTextHoverColor='white'
              buttonColor='#FFA52C4D'
              buttonHoverColor={colorPalette.yellow}
              link={`/research/${article.id}`}
            />
          </div>
        </CardContent>
      </Card>
    </FadeInSection>
  );
};

export default ResearchCard;

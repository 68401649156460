/* This text will be displayed below in the header of the research page */

import { PageHeader } from '../../types';
import photo from './cover_photo.png';

const header: PageHeader = {
  coverPhoto: photo,
  headerStatement: `Running, jumping, turning, flipping: 
  our research is pushing the boundary of what's possible with legged robotics.`,
};

export default header;

import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import header from '../../assets/research/header';
import { Article, ResearchArticles, State } from '../../types';
import { colorPalette, screenWidthSettings } from '../../constants';
import PageHeader from '../PageHeader';
import ScrollUpButton from '../ScrollUpButton';
import ResearchCard from './ResearchCard';
import ResearchRefineResults from './ResearchRefineResults';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: 'black',
      minHeight: '100vh',
      width: '100vw',
      maxWidth: screenWidthSettings.maxScreenWidth,
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
    },
    contentContainer: {
      overflow: 'hidden', //prevents scrollbar from appearing when research card animates
      minHeight: '100vh',
      width: '100%',
      maxWidth: screenWidthSettings.maxScreenWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 0,
    },
  })
);

const Research = () => {
  const classes = useStyles();
  const currentArticles = useSelector(
    (state: State): ResearchArticles => state.researchReducer.displayArticles
  );

  return (
    <Container className={classes.root} component='section'>
      <PageHeader
        title='Research'
        coverPhoto={header.coverPhoto}
        headerStatement={header.headerStatement}
        dividerColor={colorPalette.yellow}
        scrollToElementID='research-refine-results'
      />
      <Container className={classes.contentContainer}>
        <ResearchRefineResults />
        {currentArticles.map(
          (article: Article): JSX.Element => {
            return <ResearchCard article={article} key={article.id} />;
          }
        )}
      </Container>
      <ScrollUpButton arrowColor={colorPalette.yellow} />
    </Container>
  );
};

export default Research;

import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Research from '../components/research/Research';

const ResearchPage = () => (
  <Layout>
    <SEO
      title='Research | MIT Biomimetic Robotics Lab'
      description='Research page for the MIT Biomimetic Robotics Lab'
    />
    <Research />
  </Layout>
);

export default ResearchPage;

import React from 'react';
import { Link } from 'gatsby';
import { ButtonBase, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { AnimatedButtonInternalProps } from '../types';

// Animated button that uses react router to handle only internal links
const AnimatedButtonInternal = ({
  buttonText,
  buttonTextColor,
  buttonTextHoverColor,
  buttonColor,
  buttonHoverColor,
  fontSize,
  link,
}: AnimatedButtonInternalProps) => {
  // default settings for text color, size, and html target attribute
  const textNormal = buttonTextColor ? buttonTextColor : 'black';
  const textHover = buttonTextHoverColor ? buttonTextHoverColor : 'black';
  const textSize = fontSize ? fontSize : '1rem';
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        color: textNormal,
        padding: '0.5rem 1rem',
        position: 'relative',
        backgroundColor: buttonColor,
        borderRadius: theme.shape.borderRadius,
        transition: 'color 0.25s cubic-bezier(0.18, 0.85, 0.59, 0.97)',
        zIndex: 1,
        overflow: 'hidden',
        '&:hover,&:focus': {
          color: textHover,
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -1,
          backgroundColor: buttonHoverColor,
          transition: 'transform 0.5s cubic-bezier(0.18, 0.85, 0.59, 0.97)',
          transform: 'scaleX(0)',
          transformOrigin: 'left',
        },
        '&:hover::before,&:focus::before': {
          transform: 'scaleX(1)',
        },
      },
      buttonText: {
        color: 'inherit',
        userSelect: 'none',
        textTransform: 'uppercase',
        fontSize: textSize,
        fontFamily: "'Titillium Web', 'Helvetica', 'Arial', sans-serif",
        fontWeight: 400,
        [theme.breakpoints.down(769)]: {
          fontSize: '1rem',
        },
      },
    })
  );
  const classes = useStyles();
  return (
    <ButtonBase
      target='_self'
      component={Link}
      to={link}
      classes={{ root: classes.button }}
    >
      <Typography className={classes.buttonText}>{buttonText}</Typography>
    </ButtonBase>
  );
};

export default AnimatedButtonInternal;

import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { screenWidthSettings } from '../../constants';
import { ResearchState, State } from '../../types';
import { searchResearch } from '../../features/researchSlice';
import SearchBar from '../SearchBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: "'Titillium Web', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
    },
    accordion: {
      width: '100%',
    },
    accordionSummary: {
      backgroundColor: '#E6E6E6',
    },
    accordionContent: {
      backgroundColor: '#F5F5F5',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    refineResults: {
      backgroundColor: 'white',
      padding: '2rem 0 0 0',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    refineResultsTitle: {
      width: '100%',
      maxWidth: screenWidthSettings.laptopScreenSize,
      padding: '0 1rem 0 1rem',
      display: 'flex',
      // different from PeopleRefineResults styling
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    searchBarContainer: {
      [theme.breakpoints.down(769)]: {
        margin: '10px 0 10px 0', //different from PeopleRefineResults styling
        width: '100%',
        maxWidth: screenWidthSettings.searchBarWidth,
      },
    },
    divider: {
      width: '96%',
      maxWidth: '990px',
      marginTop: '2rem',
      borderTop: '2px solid black',
    },
  })
);

const ResearchRefineResults = () => {
  const classes = useStyles();
  const isMobileScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.mobileScreenSize}px)`
  );
  const isTabletScreen = useMediaQuery(
    `(max-width:${screenWidthSettings.tabletScreenSize}px)`
  );
  const researchState = useSelector(
    (state: State): ResearchState => state.researchReducer
  );
  const currentSearchQuery = researchState.searchQuery;

  //if screen size is tablet or smaller, return contents inside an accordion
  if (isTabletScreen) {
    return (
      <Accordion className={classes.accordion} id='research-refine-results'>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls='refine-results-filters'
          id='research-refine-results-header'
        >
          <Typography
            align='left'
            variant={isMobileScreen ? 'h6' : 'h5'}
            className={classes.title}
          >
            Refine Results
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionContent}>
          <div className={classes.searchBarContainer}>
            <SearchBar
              placeholder='Search research'
              actionCreator={searchResearch}
              renderLabel={false}
              searchQuery={currentSearchQuery}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <div className={classes.refineResults} id='research-refine-results'>
      <div className={classes.refineResultsTitle}>
        <Typography
          align='left'
          variant={isMobileScreen ? 'h6' : 'h5'}
          className={classes.title}
          component='h2'
        >
          Refine Results
        </Typography>
        <div className={classes.searchBarContainer}>
          <SearchBar
            placeholder='Search research'
            actionCreator={searchResearch}
            renderLabel={false}
            searchQuery={currentSearchQuery}
          />
        </div>
      </div>
      <hr className={classes.divider} />
    </div>
  );
};

export default ResearchRefineResults;
